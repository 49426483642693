import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import {
    collection,
    query,
    where,
    getDocs,
    deleteDoc,
    doc,
    updateDoc,
    arrayUnion,
    orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { AuthContext } from "../../context/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";

export default function ManageAssignedNumbers({ flow }) {
    const [assignedNumbers, setAssignedNumbers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const { user } = useContext(AuthContext);
    const confirmation = useConfirmation();
    const [sortField, setSortField] = useState("platform");
    const [sortDirection, setSortDirection] = useState("asc");

    const getIcon = (type) => {
        switch (type) {
            case "page":
                return <FacebookIcon />;
            case "telegram":
                return <TelegramIcon />;
            case "instagram":
                return <InstagramIcon />;
            default:
                return <WhatsAppIcon />;
        }
    };

    useEffect(() => {
        if (!flow?.id) return;
        loadAssignedNumbers();
    }, [flow?.id]);

    useEffect(() => {
        if (!assignedNumbers?.length) {
            setFilteredNumbers([]);
            return;
        }

        const filtered = assignedNumbers.filter((number) => {
            const searchString = searchTerm.toLowerCase();
            const name = (number.name || "").toLowerCase();
            const phone = (getClientNumber(number.chatRoomId) || "").toLowerCase();
            return name.includes(searchString) || phone.includes(searchString);
        });

        const sortedAndFiltered = getSortedNumbers(filtered);
        setFilteredNumbers(sortedAndFiltered);
        setPage(0);
    }, [searchTerm, assignedNumbers, sortField, sortDirection]);

    const loadAssignedNumbers = async () => {
        try {
            setLoading(true);
            const assignedNumbersRef = collection(db, "whatsappFlowAssignedNumbers");
            const q = query(
                assignedNumbersRef,
                where("whatsappFlowId", "==", flow.id),
                where("clientId", "==", flow.clientId),
                orderBy("date", "desc")
            );
            const snapshot = await getDocs(q);
            const numbers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAssignedNumbers(numbers || []);
        } catch (error) {
            console.error("Error loading assigned numbers:", error);
            toast.error("Failed to load assigned numbers");
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClearSearch = () => {
        setSearchTerm("");
    };

    const handleDeleteNumber = async (number) => {
        try {
            const response = await confirmation(
                "Delete Assigned Number?",
                "This action cannot be undone. Are you sure you want to delete this assigned number?"
            );

            if (!response) return;

            setLoading(true);

            // Delete from assignedNumbers collection
            await deleteDoc(doc(db, "whatsappFlowAssignedNumbers", number.id));

            // Update blockedUsers array in whatsappFlows
            const flowRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
            const client = number.chatRoomId.split("-")[1];
            await updateDoc(flowRef, {
                blockedUsers: arrayUnion(client),
            });

            // Reset triggeredBlockIds based on platform
            if (number.object) {
                switch (number.object) {
                    case "page":
                    case "instagram": {
                        const fbChatRoomRef = doc(db, "fbMessages", number.chatRoomId);
                        await updateDoc(fbChatRoomRef, { triggeredBlockIds: [] });
                        break;
                    }
                    case "telegram":
                        // Handle telegram if needed
                        break;
                    default:
                        break;
                }
            } else {
                const whatsappChatRoomRef = doc(db, "whatsappMessages", number.chatRoomId);
                await updateDoc(whatsappChatRoomRef, { triggeredBlockIds: [] });
            }

            // Update local state
            setAssignedNumbers((prev) => prev.filter((item) => item.id !== number.id));
            toast.success("Number deleted successfully");
        } catch (error) {
            console.error("Error deleting number:", error);
            toast.error("Failed to delete number");
        } finally {
            setLoading(false);
        }
    };

    const getClientNumber = (chatRoomId) => {
        return chatRoomId?.split("-")[1] || "";
    };

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortField(field);
    };

    const getSortedNumbers = (numbers) => {
        if (!numbers?.length) return [];

        return [...numbers].sort((a, b) => {
            if (sortField === "platform") {
                const platformA = a.object || "whatsapp";
                const platformB = b.object || "whatsapp";
                return sortDirection === "asc"
                    ? platformA.localeCompare(platformB)
                    : platformB.localeCompare(platformA);
            }
            return 0;
        });
    };

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <TextField
                    size="small"
                    placeholder="Search by name or number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm && (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleClearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography variant="body2">Total: {filteredNumbers.length}</Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ cursor: "pointer" }} onClick={() => handleSort("platform")}>
                                Platform{" "}
                                {sortField === "platform" && (
                                    <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                                )}
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Number/ID</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <CircularProgress size={24} />
                                </TableCell>
                            </TableRow>
                        ) : filteredNumbers.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No assigned numbers found
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredNumbers
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((number) => (
                                    <TableRow key={number.id}>
                                        <TableCell>{getIcon(number.object)}</TableCell>
                                        <TableCell>{number.name || "N/A"}</TableCell>
                                        <TableCell>{getClientNumber(number.chatRoomId)}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDeleteNumber(number)}
                                                disabled={loading}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredNumbers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}
