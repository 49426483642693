import AssistantIcon from "@mui/icons-material/Assistant";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { writeBatch } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DJCLogoGrey from "../assets/DJCLogoGrey.png";
import DJCLogoWhite from "../assets/DJCLogoWhite.png";
import EliteAlpha from "../assets/EliteAlpha.png";
import FinEliteLogoGrey from "../assets/FinEliteLogoGrey.png";
import FinEliteLogoWhite from "../assets/FinEliteLogoWhite.png";
import SimplyNiceLogo from "../assets/SimplyNiceLogoWhite.png";
import IQILogoWhite from "../assets/IQI-Pilot-White-2.png";
import IQILogo from "../assets/IQI-Pilot-2.png";
import explosoftaiLogo from "../assets/explosoft-grey.png";
import explosoftaiLogoWhite from "../assets/explosoft-white.png";
import MenuDropdown from "./MenuDropdown";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogTitle,
    Icon,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from "@mui/material";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import useNotification from "../context-utils/NotificationContext";
import { db } from "../firebase/firebase-utils";
import UnresolvedQueriesPage from "../pages/UnresolvedQueriesPage/UnresolvedQueriesPage";
import { MENU_ITEMS, MENU_ITEMS_SIGNED_OUT } from "../settings/settings";
import { ColorModeContext, tokens } from "../theme";
import FullDialog from "./FullDialog";

export default function Menu({ user }) {
    const [anchorEl, setAnchorEl] = useState(false);
    const [menu, setMenu] = useState(MENU_ITEMS);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [unresolvedQueries, setUnresolvedQueries] = useState([]);
    const [openUQP, setOpenUQP] = useState(false);

    const [queryCount, setQueryCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newCatalogName, setNewCatalogName] = useState("");
    const [creatingCatalog, setCreatingCatalog] = useState(false);
    const [newCatalogDialogOpen, setNewCatalogDialogOpen] = useState(false);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const navigate = useNavigate();
    const confirmation = useConfirmation();
    const notification = useNotification();

    const host = window.location.hostname;

    // CHANGE LOGO BASED ON HOST
    let logo, logoWhite;
    let paddingBottom = "8px";
    let paddingTop = "0px";
    let logoWidth = "100px";

    switch (host) {
        case "elitealpha.tech":
            logo = EliteAlpha;
            logoWhite = EliteAlpha;
            break;

        case "simplynice.ai":
            logo = SimplyNiceLogo;
            logoWhite = SimplyNiceLogo;
            break;

        case "app.finelite.net":
            logo = FinEliteLogoGrey;
            logoWhite = FinEliteLogoWhite;
            break;

        case "iqiglobal.me":
        case "pilot.iqiglobal.com":
        case "iqipilot.com":
        case "iqpilot.ai":
        case "localhost":
            logo = IQILogo;
            logoWhite = IQILogoWhite;
            paddingBottom = "0px";
            paddingTop = "4px";
            logoWidth = "140px";
            break;

        case "explosoftai.com":
            logo = explosoftaiLogo;
            logoWhite = explosoftaiLogoWhite;
            break;

        default:
            logo = DJCLogoGrey;
            logoWhite = DJCLogoWhite;
            break;
    }

    useEffect(() => {
        if (queryCount === 0) {
            handleDialogClose();
        }
    }, [queryCount]);

    useEffect(() => {
        if (!user) {
            setMenu(MENU_ITEMS_SIGNED_OUT);
        } else {
            setMenu(MENU_ITEMS);
        }
    }, [user]);

    useEffect(() => {
        if (!user || !user.clientId) return;

        const getCounter = async () => {
            const coll = collection(db, "unresolvedQueries");
            const q = query(coll, where("clientId", "==", user.clientId), where("status", "==", "pending"));
            const snapshot = await getCountFromServer(q);

            setQueryCount(snapshot.data().count);
        };

        try {
            getCounter();
        } catch (err) {
            console.log(err);
        }
    }, [user, refresh, unresolvedQueries]);

    useEffect(() => {
        if (!user || !user.clientId) return; // Ensure the user is available

        const unresolvedQueryCollection = collection(db, "unresolvedQueries");
        const q = query(
            unresolvedQueryCollection,
            where("clientId", "==", user.clientId),
            where("status", "==", "pending"),
            orderBy("date", "desc"),
            limit(5)
        );

        const unsubscribe = onSnapshot(q, async (snapshot) => {
            if (!snapshot.empty) {
                const lastDoc = snapshot.docs[snapshot.docs.length - 1];
                setLastVisible(lastDoc);
                setHasMore(snapshot.docs.length === 5); // Adjust based on your page size

                const queries = await Promise.all(
                    snapshot.docs.map(async (item) => {
                        const queryData = item.data();

                        // Fetch the corresponding WhatsApp Flow Name
                        const whatsappFlowRef = doc(
                            db,
                            "users",
                            user.email,
                            "whatsappFlows",
                            queryData.whatsappFlowId
                        );
                        const whatsappFlowDoc = await getDoc(whatsappFlowRef);
                        const whatsappFlowName = whatsappFlowDoc.exists()
                            ? whatsappFlowDoc.data().name
                            : "Unknown";

                        // Set up an onSnapshot listener for catalogs based on whatsappFlowId
                        const catalogsQuery = query(
                            collection(db, "catalogs"),
                            where("flowId", "==", queryData.whatsappFlowId)
                        );

                        const catalogs = [];
                        const catalogsUnsubscribe = onSnapshot(catalogsQuery, (catalogsSnapshot) => {
                            catalogs.length = 0; // Clear the catalogs array
                            catalogsSnapshot.docs.forEach((doc) => {
                                catalogs.push({
                                    id: doc.id,
                                    title: doc.data().title,
                                });
                            });

                            setUnresolvedQueries((prevQueries) =>
                                prevQueries.map((query) =>
                                    query.id === item.id ? { ...query, catalogs } : query
                                )
                            );
                        });

                        return {
                            id: item.id,
                            ...queryData,
                            whatsappFlowName,
                            catalogs, // Initially empty, will be populated by onSnapshot
                            catalogsUnsubscribe, // Store the unsubscribe function to clean up later
                        };
                    })
                );

                setUnresolvedQueries(queries);
                // setQueryCount(snapshot.size);
            } else {
                // If there are no more documents, clear the state
                setUnresolvedQueries([]);
                setQueryCount(0);
                setHasMore(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [user]);

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const handleDeleteQuery = async (id) => {
        const response = await confirmation("Delete Query?", "Click ok to confirm");
        if (response) {
            try {
                const queryRef = doc(db, "unresolvedQueries", id);
                await deleteDoc(queryRef);

                // Update the state immediately
                setUnresolvedQueries((prevQueries) => prevQueries.filter((query) => query.id !== id));

                console.log("Query successfully deleted.");
                notification("Query deleted.", "success");
            } catch (error) {
                console.error("Error deleting query: ", error);
                notification("Error deleting.", "error");
            }
        }
    };

    const handleNewCatalogClick = (queryId) => {
        setCreatingCatalog(queryId); // Set the ID of the query where the catalog is being created
        setNewCatalogName("");
        setNewCatalogDialogOpen(true); // Open the dialog
    };

    const handleNewCatalogChange = (e) => {
        setNewCatalogName(e.target.value);
    };
    const handleSaveNewCatalog = async (queryId, whatsappFlowId) => {
        if (newCatalogName.trim()) {
            const catalogRef = await addDoc(collection(db, "catalogs"), {
                title: newCatalogName,
                flowId: whatsappFlowId,
                admins: [user.email],
                date: new Date(),
            });

            // Automatically select the new catalog
            handleQueryChange(queryId, "selectedCatalog", catalogRef.id);
            setCreatingCatalog(false);
            setNewCatalogDialogOpen(false); // Close the dialog after saving
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCreatingCatalog(false);
        setNewCatalogDialogOpen(false); // Close the new catalog dialog
    };

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (event) => {
        const action = event.currentTarget.getAttribute("data-index");
        console.log(action);
        setAnchorEl(null);
        menu.every((item) => {
            if (item.action === action) {
                navigate(item.url);
                return false;
            }
            return true;
        });
    };

    const handleQueryChange = (id, field, value) => {
        setUnresolvedQueries((prevQueries) =>
            prevQueries.map((query) => (query.id === id ? { ...query, [field]: value } : query))
        );
    };

    const handleSaveQuery = async (id, whatsappFlowId) => {
        const queryToUpdate = unresolvedQueries.find((query) => query.id === id);

        // Validation check for catalog, question, and answer
        if (!queryToUpdate.selectedCatalog || !queryToUpdate.question || !queryToUpdate.answer) {
            notification("Please fill in the catalog, question, and answer.", "warning");
            return;
        }

        const response = await confirmation("Update Query?", "Click ok to confirm");
        if (response) {
            try {
                // Find the catalog name
                const selectedCatalog = queryToUpdate.catalogs.find(
                    (catalog) => catalog.id === queryToUpdate.selectedCatalog
                );
                const catalogName = selectedCatalog ? selectedCatalog.title : "Unknown";

                // Update unresolvedQueries
                const queryRef = doc(db, "unresolvedQueries", id);
                const updatedFields = {
                    question: queryToUpdate.question,
                    answer: queryToUpdate.answer,
                    selectedCatalog: queryToUpdate.selectedCatalog,
                    resolvedAt: new Date(),
                    resolvedBy: user.clientId,
                    status: "resolved",
                };

                await updateDoc(queryRef, updatedFields);
                // update catalogs
                const catalogRef = doc(db, "catalogs", queryToUpdate.selectedCatalog);

                // Fetch the existing catalog data
                const catalogDoc = await getDoc(catalogRef);

                if (catalogDoc.exists()) {
                    const existingContent = catalogDoc.data().content || "";
                    const updatedCatalogFields = {
                        content: `${existingContent}\n Q: ${queryToUpdate.question}\n A: ${queryToUpdate.answer}`,
                    };
                    // Update the document with the appended content
                    await updateDoc(catalogRef, updatedCatalogFields);
                } else {
                    console.error("Catalog does not exist.");
                }

                // Update records
                const recordsRef = collection(db, "records");
                await addDoc(recordsRef, {
                    catalogId: queryToUpdate.selectedCatalog,
                    flowId: whatsappFlowId,
                    admins: [user.email],
                    input: `Title: ${catalogName}\nQ: ${queryToUpdate.question}\nA: ${queryToUpdate.answer}`,
                    createdBy: "user",
                    date: new Date(),
                });

                notification("Query successfully updated.", "success");
            } catch (error) {
                console.error("Error updating query: ", error);
                notification("Error updating.", "error");
            }
        }
    };

    const handleLoadMore = () => {
        setOpenUQP(true);
    };

    const handleDeleteAll = async () => {
        const response = await confirmation(
            "Delete All Unresolved Queries?",
            "This action will delete all unresolved queries for your client. Are you sure you want to proceed?"
        );
        if (response) {
            try {
                const unresolvedQueryCollection = collection(db, "unresolvedQueries");
                const q = query(
                    unresolvedQueryCollection,
                    where("clientId", "==", user.clientId),
                    where("status", "==", "pending")
                );

                const querySnapshot = await getDocs(q);
                const batch = writeBatch(db);

                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });

                await batch.commit();

                setUnresolvedQueries([]);
                setQueryCount(0);
                notification("All unresolved queries have been deleted.", "success");
                handleDialogClose();
            } catch (error) {
                console.error("Error deleting all queries: ", error);
                notification("Error deleting all queries.", "error");
            }
        }
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }} pt={"55px"}>
                <AppBar
                    position="fixed"
                    style={{ backgroundColor: colors.primary[400], height: "55px" }}
                    variant="elevation"
                >
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            sx={{ color: colors.grey[100] }}
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <MenuDropdown
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            handleClick={handleClick}
                            menuDropDown={menu}
                            user={user}
                        />

                        <Typography variant="body" sx={{ flexGrow: 1 }}>
                            <img
                                onClick={() => navigate("/")}
                                width={logoWidth}
                                alt="logo"
                                src={theme.palette.mode === "dark" ? logoWhite : logo}
                                style={{ paddingBottom, paddingTop, cursor: "pointer" }}
                            />
                        </Typography>
                        {user?.role === "Super Admin" && queryCount > 0 && (
                            <Tooltip title="Unresolved queries">
                                <IconButton onClick={handleDialogOpen}>
                                    <Badge color="error" badgeContent={queryCount}>
                                        <AssistantIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        )}
                        <IconButton
                            onClick={() => {
                                window.location.reload();
                            }}
                            size="small"
                            sx={{ color: "text.disabled" }}
                        >
                            <Icon fontSize="small">refresh</Icon>
                        </IconButton>
                        <IconButton onClick={() => colorMode.toggleColorMode(user)}>
                            {theme.palette.mode === "dark" ? (
                                <DarkModeOutlinedIcon />
                            ) : (
                                <LightModeOutlinedIcon />
                            )}
                        </IconButton>
                        {user && (
                            <Button color="primary" onClick={() => navigate("/user-card-page")}>
                                <Typography variant="h7" sx={{ flexGrow: 1, color: colors.grey[100] }}>
                                    {user.displayName || ""}
                                </Typography>
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Unresolved Queries ({queryCount} items pending)</DialogTitle>
                <DialogContent
                    dividers
                    style={{ maxHeight: "400px", overflowY: "auto" }} // Fixed height with scrollable content
                >
                    {unresolvedQueries.map(
                        (query, index) =>
                            query && query.subject ? ( // Add a check to ensure the query and subject exist
                                <Accordion key={index}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        {query.subject}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2">
                                            <strong>Client Name:</strong> {query.clientName}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Client Number:</strong> {query.clientNumber}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Question:</strong> {query.userInquiry}
                                        </Typography>

                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            <strong>Analysis:</strong> {query.analysis}
                                        </Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            <strong>WhatsApp Flow Name:</strong> {query.whatsappFlowName} (
                                            {query.whatsappFlowId})
                                        </Typography>
                                        <Typography variant="body2" sx={{ mt: 2 }}>
                                            <strong>Select Catalog:</strong>
                                        </Typography>

                                        <Select
                                            value={query.selectedCatalog || ""}
                                            onChange={(e) =>
                                                handleQueryChange(query.id, "selectedCatalog", e.target.value)
                                            }
                                            fullWidth
                                        >
                                            {query.catalogs.map((catalog) => (
                                                <MenuItem key={catalog.id} value={catalog.id}>
                                                    {catalog.title}
                                                </MenuItem>
                                            ))}
                                            <MenuItem
                                                value="new"
                                                onClick={() => handleNewCatalogClick(query.id)}
                                            >
                                                + Create New Catalog
                                            </MenuItem>
                                        </Select>

                                        <TextField
                                            label="Question"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            rows={4}
                                            value={query.question}
                                            onChange={(e) =>
                                                handleQueryChange(query.id, "question", e.target.value)
                                            }
                                        />
                                        <TextField
                                            label="Answer"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            placeholder="Enter your answer here"
                                            value={query.answer}
                                            onChange={(e) =>
                                                handleQueryChange(query.id, "answer", e.target.value)
                                            }
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginTop: "16px",
                                                gap: "9px",
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => handleDeleteQuery(query.id)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDialogClose()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleSaveQuery(query.id, query.whatsappFlowId)
                                                }
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ) : null // Don't render the Accordion if query or subject is missing
                    )}
                    {hasMore && (
                        <Button onClick={handleLoadMore} variant="contained" fullWidth>
                            Load More
                        </Button>
                    )}
                    <Button
                        onClick={handleDeleteAll}
                        variant="contained"
                        color="error"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Delete All
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog open={newCatalogDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Create New Catalog</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        value={newCatalogName}
                        onChange={handleNewCatalogChange}
                        label="New Catalog Name"
                        fullWidth
                        variant="outlined"
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <Button
                            onClick={() => setNewCatalogDialogOpen(false)}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleSaveNewCatalog(
                                    creatingCatalog,
                                    unresolvedQueries.find((query) => query.id === creatingCatalog)
                                        .whatsappFlowId
                                );
                                setNewCatalogDialogOpen(false);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <FullDialog
                Component={<UnresolvedQueriesPage user={user} handleRefresh={handleRefresh} />}
                open={openUQP}
                title={`Unresolved Queries Page`}
                handleClose={() => setOpenUQP(false)}
            />
        </>
    );
}
