import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Icon,
    IconButton,
    Input,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ChatRoomData, TelegramBot, TelegramMessage } from "../../../functions/src/types/telegramTypes";
import { useInput } from "../../context-utils/InputContext";
import useList from "../../context-utils/ListContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import { ChatRoomComponent } from "./ChatRoomComponent";
import ChatMessageComponent from "./ChatMessageComponent";
import useColors from "../../hooks/useColors";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AddItemsMenu from "../../components/Menus/AddItemsMenu";
import ImageIndicator from "../../components/ChatInbox/ImageIndicator";
import SendIcon from "@mui/icons-material/Send";
import sendTelegramBotMessage from "./sendTelegramBotMessage";
import useWindowSize from "../../hooks/useWindowSize";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";

let URL = "https://receivetelegrammessage-pc6xwmvyrq-as.a.run.app";
const hostname = window.location.hostname;

if (hostname === "iqipilot.com" || hostname === "atlas.iqipilot.com" || hostname === "iqpilot.ai") {
    URL = "https://receivetelegrammessage-nsse2vqt6q-as.a.run.app";
}

type User = {
    id: string;
    displayName: string;
};

export default function ManageTelegramBots(): React.JSX.Element {
    const [bots, setBots] = useState<TelegramBot[]>([]);
    const [currentBotId, setCurrentBotId] = useState<string | null>(null);
    const [currentBot, setCurrentBot] = useState<TelegramBot | null>(null);
    const [status, setStatus] = useState<string>("");
    const [chatRooms, setChatRooms] = useState<ChatRoomData[]>([]);
    const [chatRoomsLimit, setChatRoomsLimit] = useState<number>(20);
    const [messages, setMessages] = useState<TelegramMessage[]>([]);
    const [messagesLimit, setMessagesLimit] = useState<number>(20);
    const [currentChatRoomId, setCurrentChatRoomId] = useState<string | null>(null);
    const [currentChatRoom, setCurrentChatRoom] = useState<ChatRoomData | null>(null);
    const [copySuccess, setCopySuccess] = React.useState(false);
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [image, setImage] = useState<any>(null);
    const [openEmoji, setOpenEmoji] = useState<boolean>(false);
    const [anchorElEmoji, setAnchorElEmoji] = useState<null | HTMLElement>(null);
    const [columnWidths, setColumnWidths] = useState<number[]>([30, 50, 20]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);

    const { user } = useContext(AuthContext) as { user: User };
    const input = useInput();
    const colors = useColors();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const windowSize = useWindowSize();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([30, 50, 20]);
        }
    }, [isMobile, currentColumn]);

    useEffect(() => {
        if (!currentBotId) return;

        const docRef = doc(db, "telegramBots", currentBotId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            const bot = mapDocSnapshot(doc) as TelegramBot;
            setCurrentBot(bot);
        });

        return unsubscribe;
    }, [currentBotId]);

    useEffect(() => {
        if (!currentChatRoomId) return;

        const chatRoomRef = doc(db, "telegramChatRooms", currentChatRoomId);
        const unsubscribe1 = onSnapshot(chatRoomRef, (doc) => {
            const chatRoom = mapDocSnapshot(doc) as ChatRoomData;
            setCurrentChatRoom(chatRoom);
        });

        const docRef = collection(db, "telegramChatRooms", currentChatRoomId, "messages");
        const q = query(docRef, orderBy("date", "desc"), limit(messagesLimit));
        const unsubscribe2 = onSnapshot(q, (snapshot) => {
            const messages = mapSnapshotTS<TelegramMessage>(snapshot);
            setMessages(messages);
        });

        return () => {
            unsubscribe1();
            unsubscribe2();
        };
    }, [currentChatRoomId]);

    useEffect(() => {
        if (!user?.id) return;

        const collectionRef = collection(db, "telegramBots");
        const q = query(collectionRef, where("admins", "array-contains", user.id), orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const bots = mapSnapshot(querySnapshot) as TelegramBot[];
            setBots(bots);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentBotId) return;

        const docRef = collection(db, "telegramChatRooms");

        const q = query(
            docRef,
            where("botId", "==", currentBotId),
            orderBy("date", "desc"),
            limit(chatRoomsLimit)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const chatRooms = mapSnapshot(snapshot) as ChatRoomData[];
            setChatRooms(chatRooms);
        });

        return unsubscribe;
    }, [user, currentBot]);

    const handleAddBot = async (): Promise<void> => {
        const name = await input("Enter the Telegram Bot Name", "Name", "", "");

        if (!name) return;

        const botToken = await input("Enter the Telegram Bot Token", "Token", "", "");
        if (!botToken) return;

        const collectionRef = collection(db, "telegramBots");

        // Find if the bot already exists
        const q = query(collectionRef, where("botToken", "==", botToken));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            toast.error("Bot already exists");
            return;
        }

        const { id } = await addDoc(collectionRef, {
            botToken,
            name,
            date: new Date(),
            admins: [user.id],
            createdBy: user.id,
        });

        setCurrentBotId(id);

        console.log("bot with id: ", id, " added");
        toast.success("Bot added successfully");
    };

    const list = useList();

    const handleLoadBot = async () => {
        const response = await list(bots, "Choose a bot", "name", "face", true, true, "", true);
        if (response === "addAccount") {
            handleAddBot();
        }

        if (response) {
            setCurrentBotId(response.id);
        }
    };

    const handleLink = async (): Promise<void> => {
        if (!currentBot) return;

        try {
            const requestOptions: RequestInit = {
                method: "GET",
                redirect: "follow",
            };

            if (!currentBot.webHook) {
                const response = await fetch(
                    `https://api.telegram.org/bot${currentBot.botToken}/setWebhook?url=${URL}/${currentBot.id}`,
                    requestOptions
                );

                setStatus("Connecting...");
                const result = await response.text();
                const json = JSON.parse(result);
                const status = json?.result;

                console.log(status);

                if (status) {
                    setStatus("Connected");
                    const docRef = doc(db, "telegramBots", currentBotId!);
                    await updateDoc(docRef, {
                        webHook: true,
                    });
                    setStatus("Connected");
                } else {
                    setStatus("Failed to connect");
                }
            } else {
                const response = await fetch(
                    `https://api.telegram.org/bot${currentBot.botToken}/setWebhook?remove`,
                    requestOptions
                );

                setStatus("Disconnecting...");
                const result = await response.text();
                const json = JSON.parse(result);
                const status = json?.result;

                if (status) {
                    setStatus("Disconnected");
                    const docRef = doc(db, "telegramBots", currentBotId!);
                    await updateDoc(docRef, {
                        webHook: false,
                    });
                    setStatus("Disconnected");
                } else {
                    setStatus("Failed to disconnect");
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickChatRoom = (chatRoom: ChatRoomData) => {
        console.log("ChatRoom: ", chatRoom);
        if (!chatRoom.id) return;

        setCurrentChatRoomId(chatRoom.id);
        setCurrentColumn(1);
    };

    const handleScroll = (e: any) => {
        // Check if the user is within 10 pixels of the bottom
        const nearBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

        if (nearBottom) {
            setChatRoomsLimit((prevLimit) => prevLimit + 20);
        }
    };

    const handleMessagesScroll = (e: any) => {
        // Check if the user is within 10 pixels of the top
        const nearTop = e.target.scrollHeight - e.target.clientHeight + e.target.scrollTop < 10;

        if (nearTop) {
            setMessagesLimit((prevLimit) => prevLimit + 20);
        }
    };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(currentBot?.botToken ?? "");
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const handleSendMessage = async () => {
        if (!currentChatRoomId) return;
        if (!currentBotId) return;
        if (!currentBot) return;
        if (!currentBot.botToken) return;

        const telegramMessage: TelegramMessage = {
            body: message,
            chatRoomId: currentChatRoomId!,
            createdBy: user.id,
            date: new Date(),
            from: currentBotId,
            fromAI: false,
            isGroup: currentChatRoom?.isGroup || false,
            name: user.displayName,
            source: "telegram",
            to: currentChatRoomId.split(":")[1],
            type: "chat",
        };

        const collectionRef = collection(db, "telegramChatRooms", currentChatRoomId, "messages");

        setLoading(true);

        const response: boolean = await sendTelegramBotMessage(telegramMessage, currentBot.botToken);
        await addDoc(collectionRef, { ...telegramMessage, result: response });

        setMessage("");
        setLoading(false);
    };

    const handleClickEmoji = (event: any) => {
        setAnchorElEmoji(event.currentTarget);
        setOpenEmoji(true);
    };

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} flexDirection={"row"} p={1} gap={1} alignItems={"center"} flex={1}>
                        <Avatar />
                        <Typography>{currentBot?.name}</Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(2)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                        <Button variant="contained" color="primary" onClick={handleLoadBot}>
                            Load Bot
                        </Button>
                    </Box>
                </Box>
                {currentBot && (
                    <>
                        <Box
                            flexGrow={1}
                            display="flex"
                            flexDirection={"column"}
                            width="100%"
                            sx={{ overflowY: "auto" }}
                            onScroll={handleScroll} // Add the scroll handler here
                        >
                            {chatRooms.map((chatRoom) => (
                                <Box key={chatRoom.id} width={"100%"}>
                                    <ChatRoomComponent
                                        chatRoom={chatRoom}
                                        handleClick={handleClickChatRoom}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box display="flex" alignItems={"center"} gap={1} p={1}>
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(0)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Avatar />
                    <Typography>
                        {currentChatRoom?.isGroup ? currentChatRoom?.groupName : currentChatRoom?.name}
                    </Typography>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={"column-reverse"}
                    width="100%"
                    height="100%"
                    onScroll={handleMessagesScroll} // Add the scroll handler here
                    sx={{
                        backgroundImage: `url(${colors.whatsappBackground})`,
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    {
                        // messages
                        messages.map((message) => (
                            <ChatMessageComponent
                                key={message.id}
                                message={message}
                                clientName={currentChatRoom?.name || ""}
                            />
                        ))
                        //
                    }
                </Box>
                <Box
                    p="4px"
                    width={"100%"}
                    display={currentChatRoom ? "flex" : "none"}
                    alignItems={"center"}
                    flexDirection={"row"}
                >
                    <IconButton onClick={handleClickEmoji}>
                        <InsertEmoticonIcon fontSize="large" />
                    </IconButton>
                    <AddItemsMenu attachment={image} />
                    {image && (
                        <Box display="flex" alignItems="center">
                            <ImageIndicator width={60} image={image} setImage={setImage} />
                        </Box>
                    )}

                    <Input
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        disableUnderline
                        multiline
                        maxRows={6}
                        sx={{
                            background: "white",
                            borderRadius: "6px",
                            padding: "0px 8px 0 8px",
                            minHeight: "40px",
                            backgroundColor: colors.textInput,
                        }}
                        inputRef={inputRef}
                    />
                    <IconButton
                        disabled={(!currentChatRoom && !image) || loading}
                        onClick={handleSendMessage}
                    >
                        {loading ? <CircularProgress size={24} /> : <SendIcon fontSize="large" />}
                    </IconButton>
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={columnDisplay[2] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    gap={1}
                    alignItems={"center"}
                    sx={{ borderBottom: "1px solid grey" }}
                    p={1}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(0)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Typography>Bot Settings</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} p={1} gap={1}>
                    <Typography>Name:</Typography>
                    <Typography>{currentBot?.name}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} px={1} gap={1} sx={{ maxWidth: "100%" }}>
                    <Typography>Token:</Typography>
                    <Tooltip title={copySuccess ? "Copied!" : "Click to copy"} placement="top">
                        <Box
                            onClick={handleCopyClick}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                cursor: "pointer",
                                "&:hover": { bgcolor: "action.hover" },
                                overflow: "hidden",
                            }}
                        >
                            <Typography
                                variant="caption"
                                noWrap
                                sx={{
                                    flexGrow: 1,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {currentBot?.botToken}
                            </Typography>
                            <ContentCopyIcon fontSize="small" color={copySuccess ? "success" : "action"} />
                        </Box>
                    </Tooltip>
                </Box>
                <Box display={"flex"} alignItems={"center"} p={1} gap={1}>
                    <Typography>Webhook connection:</Typography>
                    <Chip
                        disabled={!currentBot}
                        label={currentBot?.webHook ? "Connected" : "Not connected"}
                        color={currentBot?.webHook ? "primary" : "error"}
                        size="small"
                    />
                </Box>
                <Box display={"flex"} alignItems={"center"} p={1} gap={1}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleLink}
                        color={currentBot?.webHook ? "error" : "primary"}
                        disabled={!currentBot}
                    >
                        {currentBot?.webHook ? "Disconnect" : "Connect"}
                    </Button>
                    <Typography>{status}</Typography>
                </Box>
            </Box>
        </Box>
    );
}
