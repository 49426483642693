import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { AuthContext } from "../../context/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";

const ManageBlockedNumbers = ({ flow }) => {
    const [blockedNumbers, setBlockedNumbers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const [sortField, setSortField] = useState("platform");
    const [sortDirection, setSortDirection] = useState("asc");
    const { user } = useContext(AuthContext);
    const confirmation = useConfirmation();

    const getIcon = (type) => {
        switch (type) {
            case "page":
                return <FacebookIcon />;
            case "telegram":
                return <TelegramIcon />;
            case "instagram":
                return <InstagramIcon />;
            default:
                return <WhatsAppIcon />;
        }
    };

    useEffect(() => {
        if (!flow?.blockedUsers) return;
        // Convert blockedUsers array to array of objects for consistent handling
        const formattedBlockedNumbers = (flow.blockedUsers || []).map((number) => ({
            id: number,
            number: number,
            platform: number.includes("@") ? "page" : "whatsapp", // Basic platform detection
        }));
        setBlockedNumbers(formattedBlockedNumbers);
    }, [flow?.blockedUsers]);

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortField(field);
    };

    const getSortedNumbers = (numbers) => {
        if (!numbers?.length) return [];

        return [...numbers].sort((a, b) => {
            if (sortField === "platform") {
                const platformA = a.platform || "whatsapp";
                const platformB = b.platform || "whatsapp";
                return sortDirection === "asc"
                    ? platformA.localeCompare(platformB)
                    : platformB.localeCompare(platformA);
            }
            return 0;
        });
    };

    useEffect(() => {
        if (!blockedNumbers?.length) {
            setFilteredNumbers([]);
            return;
        }

        const filtered = blockedNumbers.filter((number) => {
            const searchString = searchTerm.toLowerCase();
            return number.number.toLowerCase().includes(searchString);
        });

        const sortedAndFiltered = getSortedNumbers(filtered);
        setFilteredNumbers(sortedAndFiltered);
        setPage(0);
    }, [searchTerm, blockedNumbers, sortField, sortDirection]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClearSearch = () => {
        setSearchTerm("");
    };

    const handleUnblock = async (numberToUnblock) => {
        try {
            const response = await confirmation(
                "Unblock Number?",
                "Are you sure you want to unblock this number?"
            );

            if (!response) return;

            setLoading(true);

            // Remove from blockedUsers array in whatsappFlows
            const flowRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
            const updatedBlockedUsers = flow.blockedUsers.filter((num) => num !== numberToUnblock.number);

            await updateDoc(flowRef, {
                blockedUsers: updatedBlockedUsers,
            });

            // Update local state
            setBlockedNumbers((prev) => prev.filter((item) => item.number !== numberToUnblock.number));
            toast.success("Number unblocked successfully");
        } catch (error) {
            console.error("Error unblocking number:", error);
            toast.error("Failed to unblock number");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <TextField
                    size="small"
                    placeholder="Search by number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm && (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleClearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography variant="body2">Total: {filteredNumbers.length}</Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ cursor: "pointer" }} onClick={() => handleSort("platform")}>
                                Platform{" "}
                                {sortField === "platform" && (
                                    <span>{sortDirection === "asc" ? " ↑" : " ↓"}</span>
                                )}
                            </TableCell>
                            <TableCell>Number/ID</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress size={24} />
                                </TableCell>
                            </TableRow>
                        ) : filteredNumbers.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No blocked numbers found
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredNumbers
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((number) => (
                                    <TableRow key={number.id}>
                                        <TableCell>{getIcon(number.platform)}</TableCell>
                                        <TableCell>{number.number}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleUnblock(number)}
                                                disabled={loading}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredNumbers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default ManageBlockedNumbers;
