import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { green, red } from "@mui/material/colors";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ImageComponent from "../../components/Image/ImageComponent";
import getThumbnail from "../../components/UploadFile/getThumbnail";
import isFileImage from "../../components/UploadFile/isFileImage";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, Name } from "../../themes/themes";
import checkFileExistsInStorage from "../../utils-functions/checkFileExistsInStorage";
import AIBlockNode from "./AIBlockNode";
import { removeAllFunctions, removeBlock, removeBlockAndFunctionEdge, updateBlock } from "./firebaseCall";
import FlowContext from "./FlowContext";
import IntentionNode from "./IntentionNode";
import LabelNode from "./LabelNode";
import LoadScheduleComponent from "./LoadScheduleComponent";
import StopFlowNode from "./StopFlowNode";
import WhatsappFlowNode from "./WhatsappFlowNode";

const BlockNode = ({ data, isConnectable }) => {
    const { user } = useContext(AuthContext);
    const [dialog, setDialog] = useState(null);
    const { flow, setUpdateTrigger } = useContext(FlowContext);
    const [pollQuestion, setPollQuestion] = useState("Please choose from below");
    const [isLoading, setIsLoading] = useState(false);
    const [pollItems, setPollItems] = useState([]);
    const [buttonItems, setButtonItems] = useState([]);
    const [imagePreviews, setImagePreviews] = useState({});
    const confirmation = useConfirmation();
    const notification = useNotification();
    const [files, setFiles] = useState([]);
    const { setIsDeleting } = useContext(FlowContext);
    const [existingFiles, setExistingFiles] = useState([]);
    const [currentCatalogIndex, setCurrentCatalogIndex] = useState(999);

    //rerender status to bring rerender to parent component.
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [blockObj, setBlockObj] = useState({
        timeUnit: "minutes",
        time: 0,
        message: "",
        count: null,
        name: "",
        status: "inactive",
        flowId: "",
        blockId: "",
        id: "",
    });

    const handleClickStatus = () => {
        const newStatus = blockObj.status === "active" ? "inactive" : "active";
        const updatedBlockObj = { ...blockObj, status: newStatus };
        setBlockObj(updatedBlockObj); // Update local state

        let docRef;

        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", blockObj.flowId, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", blockObj.flowId, "blocks", blockObj.id);
        }

        updateDoc(docRef, { status: newStatus }).then(() => {
            setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
        });
    };

    const handleClickDelete = async () => {
        try {
            // First block of the flow cannot be deleted
            if (blockObj.orderId === 0) {
                notification("First step cannot be deleted.");
                return;
            }

            // Check if it's a metaCatalog block with catalogs
            if (data.metaCatalog && currentCatalogIndex === 999 && catalogs?.length > 0) {
                notification("Please delete all catalogs first before deleting this block.", "error");
                return;
            }

            const response = await confirmation("Delete Block", "Confirm to delete block?");

            if (response) {
                setIsDeleting(true);
                // Delete edge that is connected to this block, all functions inside this block, and its edges.
                console.log("blockObj:", blockObj);

                await removeBlockAndFunctionEdge(user, flow, blockObj);
                // Remove the current block
                await removeAllFunctions(user, flow, blockObj);
                // Remove the current block
                await removeBlock(user, flow, blockObj);

                notification("Block deleted.");
                // Trigger rerender only after all removals are done
                triggerRerender();
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setIsDeleting(false);
        }
    };

    const updateBlockObjInFirebase = async (updatedObj) => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", updatedObj.id);
        const { onOpenDrawer, ...updatedObjWithoutOnOpenDrawer } = updatedObj;
        await updateDoc(docRef, updatedObjWithoutOnOpenDrawer);
    };

    const debouncedUpdate = useCallback(
        _.debounce((updatedObj) => updateBlockObjInFirebase(updatedObj), 1000),
        [] // Empty dependency array
    );

    const reactFlowInstance = useReactFlow();

    const handleCreateSubNode = async (
        user,
        flow,
        parentBlockObj,
        title,
        newBlockType,
        index,
        currentCatalogIndex = 0
    ) => {
        //sub node like poll is also a type of node.
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            console.log("currentCatalogIndex:", currentCatalogIndex);
            const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
            const q = query(collectionRef, where("blockType", "==", "sequential"));

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(q);
            let tempOrderId = snapshot.empty ? 0 : 999;

            //get arrPollItems to loop the count, get the count inorder to set the sub node position
            const parentRef = doc(
                db,
                "users",
                user.id,
                "whatsappFlows",
                flow.id,
                "blocks",
                parentBlockObj.id
            );
            const docSnapshot = await getDoc(parentRef);

            let newNodePositionY = 0;

            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const arrPollItems = data.arrPollItems || [];

                // if (arrPollItems.length === 0) {
                //     newNodePositionY = 475;
                // } else {
                if (parentBlockObj.metaCatalog && currentCatalogIndex !== 999) {
                    newNodePositionY = 350 + index * 60;
                } else {
                    newNodePositionY = 475 + index * 60;
                }
                // }
            } else {
                console.log("No such parent node!");
            }

            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: title,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x:
                            parentBlockObj.metaCatalog && currentCatalogIndex !== 999
                                ? 340 + currentCatalogIndex * 320
                                : 20,
                        y: newNodePositionY,
                    },
                    updatedDate: "",
                    date: new Date(),
                    blockType: newBlockType,
                    parentNode: parentBlockObj.id,
                    extent: "parent",
                    type: "PollNode",
                    draggable: false,
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            return docRef.id;
        } catch (err) {
            console.log("BlockNode.jsx:", err);
        }
    };

    const handleChangePollQuestion = (e) => {
        setPollQuestion(e.target.value);
    };

    const handleDeleteQuestion = async () => {
        try {
            // For both catalog buttons and main node buttons, prevent deletion if only 1 button remains
            if (data.metaCatalog) {
                if (currentCatalogIndex !== 999) {
                    const currentCatalog = catalogs[currentCatalogIndex];
                    const buttonIds = currentCatalog.buttonIds || [];
                    if (buttonIds.length <= 1) {
                        notification("Cannot delete. Each catalog must have at least 1 button.", "error");
                        return;
                    }
                } else {
                    // Check main node buttons
                    const mainButtonIds = blockObj.arrPollItems || [];
                    if (mainButtonIds.length <= 1) {
                        notification("Cannot delete. Main node must have at least 1 button.", "error");
                        return;
                    }
                }
            }

            const response = await confirmation(
                data.metaCatalog ? "Delete buttons" : "Delete poll question and all selections",
                data.metaCatalog
                    ? "Confirm to delete all buttons?"
                    : "Confirm to delete all poll question and selections?"
            );

            if (response) {
                if (data.metaCatalog) {
                    if (currentCatalogIndex !== 999) {
                        // Handling catalog-specific buttons deletion
                        const newCatalogs = [...catalogs];
                        const currentCatalog = newCatalogs[currentCatalogIndex];
                        const buttonIds = currentCatalog.buttonIds || [];

                        // Delete all button nodes and their relations
                        await Promise.all(
                            buttonIds.map(async (buttonId) => {
                                await removeBlockAndFunctionEdge(user, flow, { id: buttonId });
                                await removeAllFunctions(user, flow, { id: buttonId });
                                await removeBlock(user, flow, { id: buttonId });
                            })
                        );

                        // Update the specific catalog
                        newCatalogs[currentCatalogIndex] = {
                            ...currentCatalog,
                            buttons: [],
                            buttonIds: [],
                        };

                        // Update in Firebase
                        const docRef = doc(
                            db,
                            "users",
                            user.id,
                            "whatsappFlows",
                            flow.id,
                            "blocks",
                            blockObj.id || blockObj.blockId
                        );

                        await updateDoc(docRef, { catalogs: newCatalogs });
                        setCatalogs(newCatalogs);
                        setButtonItems([]); // Clear button items state
                    } else {
                        // Delete main node buttons
                        if (blockObj.arrPollItems) {
                            // Delete all button nodes and their relations
                            await Promise.all(
                                blockObj.arrPollItems.map(async (buttonId) => {
                                    await removeBlockAndFunctionEdge(user, flow, { id: buttonId });
                                    await removeAllFunctions(user, flow, { id: buttonId });
                                    await removeBlock(user, flow, { id: buttonId });
                                })
                            );

                            // Update main block
                            const docRef = doc(
                                db,
                                "users",
                                user.id,
                                "whatsappFlows",
                                flow.id,
                                "blocks",
                                blockObj.id || blockObj.blockId
                            );

                            await updateDoc(docRef, {
                                pollObject: {
                                    pollName: "",
                                    pollOptions: [],
                                },
                                arrPollItems: [],
                            });

                            setBlockObj({
                                ...blockObj,
                                pollObject: {
                                    pollName: "",
                                    pollOptions: [],
                                },
                                arrPollItems: [],
                            });
                        }
                        setPollItems([]); // Clear poll items state
                    }
                } else {
                    // Handle poll deletion
                    if (blockObj.arrPollItems) {
                        // Delete all poll nodes and their relations
                        await Promise.all(
                            blockObj.arrPollItems.map(async (pollId) => {
                                await removeBlockAndFunctionEdge(user, flow, { id: pollId });
                                await removeAllFunctions(user, flow, { id: pollId });
                                await removeBlock(user, flow, { id: pollId });
                            })
                        );

                        // Update main block
                        const docRef = doc(
                            db,
                            "users",
                            user.id,
                            "whatsappFlows",
                            flow.id,
                            "blocks",
                            blockObj.id || blockObj.blockId
                        );

                        await updateDoc(docRef, {
                            pollObject: {
                                pollName: "Please choose from below",
                                pollOptions: [],
                            },
                            arrPollItems: [],
                        });

                        setBlockObj({
                            ...blockObj,
                            pollObject: {
                                pollName: "Please choose from below",
                                pollOptions: [],
                            },
                            arrPollItems: [],
                        });
                    }
                    setPollItems([]);
                    setPollQuestion("Please choose from below");
                }

                notification(
                    data.metaCatalog
                        ? "Buttons deleted successfully."
                        : "Poll question and selections deleted."
                );
                triggerRerender();
                handleClose();
            }
        } catch (err) {
            console.error("Error deleting:", err);
            notification(
                data.metaCatalog
                    ? "Error deleting buttons. Please try again."
                    : "Error deleting poll. Please try again.",
                "error"
            );
        }
    };

    const handleDeletePollItem = (index) => {
        if (data.metaCatalog && pollItems.length <= 1) {
            notification("Cannot delete. At least one button is required.", "error");
            return;
        }
        const newItems = pollItems.filter((_, i) => i !== index);
        setPollItems(newItems);
    };

    const handleDeleteButton = (index) => {
        // Prevent deletion if only 1 button remains
        if (buttonItems.length <= 1) {
            notification("Cannot delete. At least one button is required.", "error");
            return;
        }
        const newItems = buttonItems.filter((_, i) => i !== index);
        setButtonItems(newItems);
    };

    const handleAddPollItem = () => {
        console.log("currentCatalogIndex:", currentCatalogIndex);
        if (data.metaCatalog === true && currentCatalogIndex !== 999) {
            // For catalog buttons
            if (buttonItems.length >= 3) {
                notification("Maximum 3 buttons allowed per catalog.", "error");
                return;
            }
            const newButtonItems = [...buttonItems, "New Button"];
            console.log("New newButtonItems:", newButtonItems);
            setButtonItems(newButtonItems);
        } else {
            if (data.metaCatalog === true && currentCatalogIndex == 999) {
                // For main node poll items
                if (pollItems.length >= 3) {
                    notification("Maximum limit for button is 3.", "error");
                    return;
                }
                const newPollItems = [...pollItems, "New Button"];
                console.log("New Button:", newPollItems);
                setPollItems(newPollItems);
            } else {
                if (pollItems.length >= 12) {
                    notification("Maximum limit for poll selection is 12.", "error");
                    return;
                }
                const newPollItems = [...pollItems, "New Poll Selection"];
                console.log("New pollItems:", newPollItems);
                setPollItems(newPollItems);
            }
        }
    };

    const handleChangePollItem = (e, index) => {
        const newItems = [...pollItems];
        newItems[index] = e.target.value;
        setPollItems(newItems);
    };

    const handleCatalogFiles = async (files, catalogIndex) => {
        let docRef;
        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        }

        // Get current catalogs
        const newCatalogs = [...catalogs];
        newCatalogs[catalogIndex] = {
            ...newCatalogs[catalogIndex],
            files: [files[0]],
        };

        await updateDoc(docRef, { catalogs: newCatalogs });
        notification("Files uploaded successfully.");
        triggerRerender();

        if (files && files[0]) {
            const file = files[0];
            if (isFileImage(file)) {
                const thumbnailURL = await getThumbnail(file);
                setImagePreviews((prev) => ({
                    ...prev,
                    [catalogIndex]: {
                        file: file,
                        preview: thumbnailURL || URL.createObjectURL(file),
                    },
                }));
            }
        }
    };

    const handleFiles = async (files) => {
        let docRef;
        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        }
        await updateDoc(docRef, { files: [files[0]] });
        notification("Files uploaded successfully.");
        triggerRerender();
    };

    async function checkThumbnails(files) {
        if (files) {
            const newFiles = [...files];
            await Promise.all(
                newFiles.map(async (file, index) => {
                    if (isFileImage(file)) {
                        const thumbnailURL = await getThumbnail(file);
                        if (thumbnailURL) {
                            newFiles[index] = { ...file, thumbnailURL }; // Ensure the updated file object is correctly reassigned
                        }
                    }
                    return null; // Return null for non-image files or when no thumbnailURL is updated
                })
            );
            return newFiles; // Return the modified files array
        }
        return []; // Return an empty array if files is falsy to ensure function always returns an array
    }

    const handleCatalogFunctionClick = (catalogIndex) => {
        setCurrentCatalogIndex(catalogIndex);

        const currentCatalog = catalogs[catalogIndex];
        console.log("catalogIndex:", catalogIndex);
        console.log("currentCatalog:", currentCatalog);
        if (currentCatalog?.buttons?.length > 0) {
            console.log("have buttons");
            setButtonItems(currentCatalog.buttons);
        } else {
            console.log("no buttons");
            setButtonItems([]); // Reset if no buttons
        }

        console.log(`Opening function dialog for Catalog ${catalogIndex + 1}`, {
            catalogTitle: catalogs[catalogIndex]?.title || "Untitled",
            catalogData: catalogs[catalogIndex],
        });
        handleClickOpen();
    };

    const handleSavePoll = async () => {
        try {
            if (data.metaCatalog) {
                console.log("currentCatalogIndex:", currentCatalogIndex);
                if (currentCatalogIndex !== 999) {
                    // Handling catalog-specific buttons
                    const newCatalogs = [...catalogs];
                    const currentCatalog = newCatalogs[currentCatalogIndex];
                    const currentButtonIds = currentCatalog.buttonIds || [];
                    const currentButtonCount = currentButtonIds.length;
                    const newButtonCount = buttonItems.length;

                    if (newButtonCount > currentButtonCount) {
                        // Create new buttons for additional options
                        const newButtonIds = await Promise.all(
                            buttonItems.slice(currentButtonCount).map(async (buttonText, index) => {
                                return await handleCreateSubNode(
                                    user,
                                    flow,
                                    blockObj,
                                    buttonText,
                                    "PollNode",
                                    currentButtonCount + index,
                                    currentCatalogIndex
                                );
                            })
                        );

                        // Update existing buttons with new positions
                        await Promise.all(
                            buttonItems.slice(0, currentButtonCount).map(async (buttonText, index) => {
                                const newPosition = {
                                    x: 340 + currentCatalogIndex * 320,
                                    y: 350 + index * 60,
                                };
                                await updateBlock(user, flow, currentButtonIds[index], {
                                    name: buttonText,
                                    position: newPosition,
                                });
                            })
                        );

                        // Combine existing and new button IDs
                        const updatedButtonIds = [...currentButtonIds, ...newButtonIds];

                        // Update the specific catalog
                        newCatalogs[currentCatalogIndex] = {
                            ...currentCatalog,
                            buttons: buttonItems,
                            buttonIds: updatedButtonIds.filter(Boolean),
                        };
                    } else if (newButtonCount < currentButtonCount) {
                        // Create a map of button text to existing IDs
                        const buttonTextToIdMap = new Map();
                        currentButtonIds.forEach((id, index) => {
                            if (index < currentCatalog.buttons.length) {
                                buttonTextToIdMap.set(currentCatalog.buttons[index], id);
                            }
                        });

                        // Determine which IDs to keep
                        const keepButtonIds = buttonItems
                            .map((buttonText) => buttonTextToIdMap.get(buttonText))
                            .filter(Boolean);

                        // Find IDs to remove
                        const buttonsToRemove = currentButtonIds.filter((id) => !keepButtonIds.includes(id));

                        // Delete removed buttons and their relations
                        await Promise.all(
                            buttonsToRemove.map(async (buttonId) => {
                                await removeBlockAndFunctionEdge(user, flow, { id: buttonId });
                                await removeAllFunctions(user, flow, { id: buttonId });
                                await removeBlock(user, flow, { id: buttonId });
                            })
                        );

                        // Update remaining buttons with new positions
                        await Promise.all(
                            buttonItems.map(async (buttonText, index) => {
                                const buttonId = keepButtonIds[index];
                                if (buttonId) {
                                    const newPosition = {
                                        x: 340 + currentCatalogIndex * 320,
                                        y: 350 + index * 60,
                                    };
                                    await updateBlock(user, flow, buttonId, {
                                        name: buttonText,
                                        position: newPosition,
                                    });
                                }
                            })
                        );

                        // Update the specific catalog
                        newCatalogs[currentCatalogIndex] = {
                            ...currentCatalog,
                            buttons: buttonItems,
                            buttonIds: keepButtonIds,
                        };
                    } else {
                        // Just updating existing buttons (same count)
                        await Promise.all(
                            buttonItems.map(async (buttonText, index) => {
                                const newPosition = {
                                    x: 340 + currentCatalogIndex * 320,
                                    y: 350 + index * 60,
                                };
                                await updateBlock(user, flow, currentButtonIds[index], {
                                    name: buttonText,
                                    position: newPosition,
                                });
                            })
                        );

                        // Update the specific catalog
                        newCatalogs[currentCatalogIndex] = {
                            ...currentCatalog,
                            buttons: buttonItems,
                            buttonIds: currentButtonIds,
                        };
                    }

                    // Update in Firebase
                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, { catalogs: newCatalogs });
                    setCatalogs(newCatalogs);

                    notification("Catalog buttons saved successfully.");
                    triggerRerender();
                    handleClose();
                    return; // Exit after handling catalog buttons
                }

                // Original button handling code continues here for non-catalog buttons
                const currentButtonIds = blockObj.arrPollItems || [];
                const currentButtonCount = currentButtonIds.length;
                const newButtonCount = pollItems.length;

                // Case 1: Adding new buttons
                if (newButtonCount > currentButtonCount) {
                    // Create new buttons for additional options
                    const newButtonIds = await Promise.all(
                        pollItems.slice(currentButtonCount).map(async (buttonText, index) => {
                            return await handleCreateSubNode(
                                user,
                                flow,
                                blockObj,
                                buttonText,
                                "PollNode",
                                currentButtonCount + index,
                                999
                            );
                        })
                    );

                    // Update existing buttons
                    await Promise.all(
                        pollItems.slice(0, currentButtonCount).map(async (buttonText, index) => {
                            await updateBlock(user, flow, currentButtonIds[index], { name: buttonText });
                        })
                    );

                    // Combine existing and new button IDs
                    const updatedButtonIds = [...currentButtonIds, ...newButtonIds];

                    // Update Firebase
                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: "", // Empty for buttons
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedButtonIds.filter(Boolean),
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: "",
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedButtonIds.filter(Boolean),
                    });
                }
                // Case 2: Removing buttons
                else if (newButtonCount < currentButtonCount) {
                    // Create a map of button text to existing IDs
                    const buttonTextToIdMap = new Map();
                    currentButtonIds.forEach((id, index) => {
                        if (index < blockObj.pollObject.pollOptions.length) {
                            buttonTextToIdMap.set(blockObj.pollObject.pollOptions[index], id);
                        }
                    });

                    // Determine which IDs to keep
                    const keepButtonIds = pollItems
                        .map((buttonText) => buttonTextToIdMap.get(buttonText))
                        .filter(Boolean);

                    // Find IDs to remove
                    const buttonsToRemove = currentButtonIds.filter((id) => !keepButtonIds.includes(id));

                    // Delete removed buttons and their relations
                    await Promise.all(
                        buttonsToRemove.map(async (buttonId) => {
                            await removeBlockAndFunctionEdge(user, flow, { id: buttonId });
                            await removeAllFunctions(user, flow, { id: buttonId });
                            await removeBlock(user, flow, { id: buttonId });
                        })
                    );

                    // Update remaining buttons
                    await Promise.all(
                        pollItems.map(async (buttonText, index) => {
                            const buttonId = keepButtonIds[index];
                            if (buttonId) {
                                const newPosition = {
                                    x:
                                        data.metaCatalog && currentCatalogIndex !== 999
                                            ? 340 + currentCatalogIndex * 320
                                            : 20,
                                    y:
                                        data.metaCatalog && currentCatalogIndex !== 999
                                            ? 350 + index * 60
                                            : 475 + index * 60,
                                };

                                await updateBlock(user, flow, buttonId, {
                                    name: buttonText,
                                    position: newPosition,
                                });
                            }
                        })
                    );

                    // Update main block
                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: "",
                            pollOptions: pollItems,
                        },
                        arrPollItems: keepButtonIds,
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: "",
                            pollOptions: pollItems,
                        },
                        arrPollItems: keepButtonIds,
                    });
                }
                // Case 3: Just updating existing buttons (same count)
                else {
                    await Promise.all(
                        pollItems.map(async (buttonText, index) => {
                            await updateBlock(user, flow, currentButtonIds[index], { name: buttonText });
                        })
                    );

                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: "",
                            pollOptions: pollItems,
                        },
                        arrPollItems: currentButtonIds,
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: "",
                            pollOptions: pollItems,
                        },
                        arrPollItems: currentButtonIds,
                    });
                }
            } else {
                // Poll handling
                if (pollItems.length < 2) {
                    notification("A poll must have at least 2 selection.", "error");
                    return;
                }

                let defaultQuestion = pollQuestion.trim() || "Please choose from below";
                const currentPollIds = blockObj.arrPollItems || [];
                const currentPollCount = currentPollIds.length;
                const newPollCount = pollItems.length;
                // Create or update poll items
                if (newPollCount > currentPollCount) {
                    // Create new poll items for the additional options
                    const newPollIds = await Promise.all(
                        pollItems.slice(currentPollCount).map(async (pollText, index) => {
                            return await handleCreateSubNode(
                                user,
                                flow,
                                blockObj,
                                pollText,
                                "PollNode",
                                currentPollCount + index
                            );
                        })
                    );

                    // Update existing poll items
                    await Promise.all(
                        pollItems.slice(0, currentPollCount).map(async (pollText, index) => {
                            await updateBlock(user, flow, currentPollIds[index], { name: pollText });
                        })
                    );

                    // Combine existing and new poll IDs
                    const updatedPollIds = [...currentPollIds, ...newPollIds];

                    // Update Firebase
                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedPollIds.filter(Boolean),
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedPollIds.filter(Boolean),
                    });
                }
                // Case 2: Removing polls
                else if (newPollCount < currentPollCount) {
                    // Get IDs of polls to be removed
                    const pollsToRemove = currentPollIds.slice(newPollCount);

                    // Delete removed polls from Firebase
                    await Promise.all(
                        pollsToRemove.map(async (pollId) => {
                            // Delete the poll block
                            const pollRef = doc(
                                db,
                                "users",
                                user.id,
                                "whatsappFlows",
                                flow.id,
                                "blocks",
                                pollId
                            );
                            await deleteDoc(pollRef);

                            // Delete any associated relations
                            const relationsQuery = query(
                                collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
                                where("sourceNodeId", "==", pollId)
                            );
                            const relationsDocs = await getDocs(relationsQuery);
                            await Promise.all(relationsDocs.docs.map((doc) => deleteDoc(doc.ref)));
                        })
                    );

                    // Update remaining polls
                    await Promise.all(
                        pollItems.map(async (pollText, index) => {
                            await updateBlock(user, flow, currentPollIds[index], { name: pollText });
                        })
                    );

                    // Update main block with new poll configuration
                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    const updatedPollIds = currentPollIds.slice(0, newPollCount);

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedPollIds,
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: updatedPollIds,
                    });
                }
                // Case 3: Just updating existing polls (same count)
                else {
                    await Promise.all(
                        pollItems.map(async (pollText, index) => {
                            await updateBlock(user, flow, currentPollIds[index], { name: pollText });
                        })
                    );

                    const docRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        blockObj.id || blockObj.blockId
                    );

                    await updateDoc(docRef, {
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: currentPollIds,
                    });

                    setBlockObj({
                        ...blockObj,
                        pollObject: {
                            pollName: defaultQuestion,
                            pollOptions: pollItems,
                        },
                        arrPollItems: currentPollIds,
                    });
                }
            }

            notification(data.metaCatalog ? "Buttons saved successfully." : "Poll saved successfully.");
            triggerRerender();
            handleClose();
        } catch (error) {
            console.error("Error saving:", error);
            notification(
                data.metaCatalog
                    ? "Error saving buttons. Please try again."
                    : "Error saving poll. Please try again.",
                "error"
            );
        }
    };

    useEffect(() => {
        if (data?.catalogs) {
            setCatalogs(data.catalogs);
        }
    }, [data]);

    useEffect(() => {
        setBlockObj(data);

        if (data?.pollObject?.pollOptions) {
            setPollItems(data.pollObject.pollOptions);
        }

        if (data?.pollObject?.pollName) {
            setPollQuestion(data.pollObject.pollName);
        }

        if (data?.metaCatalog && data?.pollObject?.pollOptions) {
            setPollItems(data.pollObject.pollOptions);
        }
    }, [data]);

    useEffect(() => {
        const checkFiles = async () => {
            if (data.files && data.files.length > 0) {
                const checkedFiles = await Promise.all(
                    data.files.map(async (file) => {
                        const exists = await checkFileExistsInStorage(file.downloadURL);
                        return exists ? file : null;
                    })
                );

                const filteredFiles = checkedFiles.filter((file) => file !== null);
                setExistingFiles(filteredFiles);

                // If any files were removed, update Firebase
                if (filteredFiles.length !== data.files.length) {
                    // Check if blockObj.id exists, if not use blockObj.blockId
                    const blockId = data.id || data.blockId;

                    if (!blockId) {
                        console.error("No valid block ID found");
                        return;
                    }

                    const docRef = doc(db, "users", user.id, "whatsappFlows", data.flowId, "blocks", blockId);
                    await updateDoc(docRef, { files: filteredFiles });
                    triggerRerender(); // Assuming this function exists to update the parent component
                }
            } else {
                setExistingFiles([]);
            }
        };

        checkFiles();
    }, []);

    const [catalogs, setCatalogs] = useState([]);

    const handleAddCatalog = async () => {
        if (catalogs.length >= 9) {
            notification("Maximum 9 catalogs allowed.", "error");
            return;
        }
       
        try {
             // Create new catalog with default button
        const newCatalog = {
            title: "",
            subTitle: "",
            files: [],
            buttons: ["New Button"], // Default button text
        };

        // Add the catalog first
        const newCatalogs = [...catalogs, newCatalog];
            // Create the default button node
            const buttonId = await handleCreateSubNode(
                user,
                flow,
                blockObj,
                "New Button",
                "PollNode",
                0, // First button
                newCatalogs.length - 1 // Index of the new catalog
            );

            // Update the catalog with the button ID
            newCatalog.buttonIds = [buttonId];

            // Update Firebase
            const docRef = doc(
                db,
                "users",
                user.id,
                "whatsappFlows",
                flow.id,
                "blocks",
                blockObj.id || blockObj.blockId
            );

            await updateDoc(docRef, { catalogs: newCatalogs });

            // Update local state with the complete catalog data
            setCatalogs(newCatalogs);
            triggerRerender();
        } catch (error) {
            console.error("Error creating default button:", error);
            notification("Error creating catalog. Please try again.", "error");

            // Remove the catalog if button creation failed
            setCatalogs(catalogs);
        }
    };

    const handleDeleteFile = async (fileToRemove) => {
        if (isLoading) return; // Prevent the function from proceeding if it's already loading

        setIsLoading(true);
        try {
            let id = "";
            if (blockObj.blockId) {
                id = blockObj.blockId;
            } else {
                id = blockObj.id;
            }
            const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const existingFiles = docSnap.data().files || [];
                const updatedFiles = existingFiles.filter(
                    (file) => file.downloadURL !== fileToRemove.downloadURL
                );
                await updateDoc(docRef, { files: updatedFiles });
                notification("File removed successfully.");
            }

            triggerRerender();
        } catch (err) {
            console.log("err:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteCatalogFile = async (catalogIndex) => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            let id = blockObj.blockId || blockObj.id;
            const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", id);

            // Create new catalogs array with empty files for the specified index
            const newCatalogs = [...catalogs];
            newCatalogs[catalogIndex] = {
                ...newCatalogs[catalogIndex],
                files: [], // Reset files to empty array
            };

            // Update the document with the new catalogs array
            await updateDoc(docRef, { catalogs: newCatalogs });

            // Update local state
            setCatalogs(newCatalogs);
            setImagePreviews((prev) => {
                const newPreviews = { ...prev };
                delete newPreviews[catalogIndex];
                return newPreviews;
            });

            notification("File removed successfully.");
            triggerRerender();
        } catch (err) {
            console.log("err:", err);
            notification("Error removing file.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCatalogPollItems = (catalogIndex, items) => {
        const newCatalogs = [...catalogs];
        newCatalogs[catalogIndex] = {
            ...newCatalogs[catalogIndex],
            pollItems: items,
        };
        setCatalogs(newCatalogs);
        const updatedValue = {
            ...blockObj,
            catalogs: newCatalogs,
        };
        debouncedUpdate(updatedValue);
    };

    // Add this useEffect to handle initial catalog files
    useEffect(() => {
        const initializeCatalogPreviews = async () => {
            if (data?.catalogs) {
                const newPreviews = {};
                await Promise.all(
                    data.catalogs.map(async (catalog, index) => {
                        if (catalog.files?.[0]) {
                            const file = catalog.files[0];
                            if (isFileImage(file)) {
                                const thumbnailURL = await getThumbnail(file);
                                newPreviews[index] = {
                                    file: file,
                                    preview: thumbnailURL || file.downloadURL,
                                };
                            }
                        }
                    })
                );
                setImagePreviews(newPreviews);
            }
        };

        initializeCatalogPreviews();
    }, [data.catalogs]); // Only run when catalogs data changes

    const handleChangeButtonItem = (e, index) => {
        // Remove emojis and keep only allowed characters
        const sanitizedValue = e.target.value.replace(
            /[\u{1F300}-\u{1F9FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{1F191}-\u{1F251}]|[\u{1F004}]|[\u{1F0CF}]|[\u{1F170}-\u{1F171}]|[\u{1F17E}-\u{1F17F}]|[\u{1F18E}]|[\u{3030}]|[\u{2B50}]|[\u{2B55}]|[\u{2934}-\u{2935}]|[\u{2B05}-\u{2B07}]|[\u{2B1B}-\u{2B1C}]|[\u{3297}]|[\u{3299}]|[\u{303D}]|[\u{00A9}]|[\u{00AE}]|[\u{2122}]/gu,
            ""
        );

        const newItems = [...buttonItems];
        newItems[index] = sanitizedValue;
        setButtonItems(newItems);
    };

    switch (data.blockType) {
        case "action":
        case "trigger":
            return <AIBlockNode data={data} isConnectable={isConnectable} />;
        case "whatsappFlow":
            return <WhatsappFlowNode data={data} isConnectable={isConnectable} />;
        case "labelNode":
            return <LabelNode data={data} isConnectable={isConnectable} />;
        case "stopFlowNode":
            return <StopFlowNode data={data} isConnectable={isConnectable} />;
        case "intention":
            return <IntentionNode data={data} isConnectable={isConnectable} />;
        default:
            break;
    }

    return (
        <>
            <style>
                {`
        @keyframes blinkGlow {
            0%, 100% {
                box-shadow: 0 0 40px #f7c052; // Full intensity
            }
            50% {
                box-shadow: 0 0 20px #f7c052; // Reduced intensity
            }
        }
        .glowing {
            animation: blinkGlow 1s ease-in-out infinite;
            z-index: 999; // Added z-index
        }
    `}
            </style>
            {dialog && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialog.content}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            )}
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <div
                    className="text-updater-node"
                    style={{ width: "300px", zIndex: blockObj.name === "New Node Name" ? 999 : 0 }}
                    onClick={() => console.log(blockObj)}
                >
                    {blockObj.orderId === 0 ? (
                        ""
                    ) : (
                        <Handle
                            type="target"
                            position={Position.Left}
                            isConnectable={isConnectable}
                            style={{
                                background: "#525252",
                                width: "15px",
                                height: "15px",
                                borderRadius: "5px",
                                opacity: 0.5,
                                zIndex: 1001,
                            }}
                        />
                    )}

                    <Paper
                        className={blockObj.message === "" ? "glowing" : ""}
                        sx={{
                            padding: "8px",
                            zIndex: blockObj.name === "New Node Name" ? 1000 : "auto",
                            position: blockObj.name === "New Node Name" ? "relative" : "static",
                        }}
                    >
                        <Grid key={blockObj.blockId} container display="flex" spacing={1}>
                            <Grid item xs={6}>
                                {/*to pass in blockId,flowId,user to loadschedulecomponent*/}

                                <LoadScheduleComponent
                                    blockId={blockObj.blockId}
                                    flowId={blockObj.flowId}
                                    user={user}
                                    blockName={blockObj.name}
                                />
                            </Grid>

                            <Grid item xs={4} display="flex" justifyContent="flex-end">
                                {blockObj.orderId !== null &&
                                    blockObj.orderId !== undefined &&
                                    blockObj.orderId === 0 && (
                                        <Chip color="warning" size="small" label="FIRST" />
                                    )}
                            </Grid>

                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                {blockObj.count !== null && blockObj.count !== undefined && (
                                    <Chip
                                        color={blockObj.count !== 0 ? "primary" : "success"}
                                        size="small"
                                        label={blockObj.count}
                                        onClick={() => console.log(data)}
                                    />
                                )}
                            </Grid>
                            <Grid item display={"flex"} xs={8}>
                                <Box display="flex" alignItems="center" gap="8px">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="Block Name"
                                        value={blockObj.name}
                                        onChange={(e) => {
                                            const updatedValue = { ...blockObj, name: e.currentTarget.value };
                                            setBlockObj(updatedValue); // Update the state
                                            debouncedUpdate(updatedValue); // Pass the updated value immediately
                                        }}
                                        className="nodrag"
                                    />
                                </Box>
                            </Grid>
                            <Grid item display={"flex"} justifyContent={"flex-end"} xs={4}>
                                <IconButton size="small" onClick={() => handleClickStatus()}>
                                    <Name color={blockObj.status === "active" ? green[500] : red[500]}>
                                        {blockObj.status || "inactive"}
                                    </Name>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleClickDelete()}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Grid>

                            {data.metaCatalog === true ? (
                                <>
                                    <Grid item display="flex" xs={12} className="nowheel">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Title"
                                            className="nodrag nowheel"
                                            multiline
                                            rows={5}
                                            value={blockObj.title}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation(); // Stop mousedown event
                                            }}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                const updatedValue = {
                                                    ...blockObj,
                                                    title: e.currentTarget.value,
                                                };
                                                setBlockObj(updatedValue);
                                                debouncedUpdate(updatedValue);
                                            }}
                                            sx={{
                                                // Prevent text selection issues
                                                "& .MuiInputBase-input": {
                                                    cursor: "text !important",
                                                },
                                                // Prevent dragging
                                                userSelect: "text",
                                                pointerEvents: "auto",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item display="flex" xs={12} className="nowheel">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Sub Title"
                                            className="nodrag nowheel"
                                            multiline
                                            rows={5}
                                            value={blockObj.subTitle}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation(); // Stop mousedown event
                                            }}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                const updatedValue = {
                                                    ...blockObj,
                                                    subTitle: e.currentTarget.value,
                                                };
                                                setBlockObj(updatedValue);
                                                debouncedUpdate(updatedValue);
                                            }}
                                            sx={{
                                                // Prevent text selection issues
                                                "& .MuiInputBase-input": {
                                                    cursor: "text !important",
                                                },
                                                // Prevent dragging
                                                userSelect: "text",
                                                pointerEvents: "auto",
                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item display="flex" xs={12} className="nowheel">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Message"
                                            className="nodrag nowheel"
                                            multiline
                                            rows={10}
                                            value={blockObj.message}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation(); // Stop mousedown event
                                            }}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                const updatedValue = {
                                                    ...blockObj,
                                                    message: e.currentTarget.value,
                                                };
                                                setBlockObj(updatedValue);
                                                debouncedUpdate(updatedValue);
                                            }}
                                            sx={{
                                                // Prevent text selection issues
                                                "& .MuiInputBase-input": {
                                                    cursor: "text !important",
                                                },
                                                // Prevent dragging
                                                userSelect: "text",
                                                pointerEvents: "auto",
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item display="flex" xs={4}>
                                <Box display={"flex"} alignContent={"center"}>
                                    <UploadFilesButton handleFile={handleFiles} label="Add File" />
                                </Box>
                            </Grid>

                            <Grid item display="flex" xs={4}>
                                {/* <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    autoComplete="off"
                                    value={blockObj.time || 0}
                                    onChange={(e) => {
                                        const updatedValue = { ...blockObj, time: Number(e.target.value) };
                                        setBlockObj(updatedValue);
                                        debouncedUpdate(updatedValue); // Pass the updated value directly
                                    }}
                                    className="nodrag"
                                /> */}
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    autoComplete="off"
                                    value={blockObj.time || 0}
                                    onChange={(e) => {
                                        const value = Math.max(0, Math.floor(Number(e.target.value)));
                                        const updatedValue = { ...blockObj, time: value };
                                        setBlockObj(updatedValue);
                                        debouncedUpdate(updatedValue);
                                    }}
                                    inputProps={{
                                        min: 0,
                                        step: 1,
                                    }}
                                    className="nodrag"
                                />
                            </Grid>

                            <Grid item display="flex" xs={4}>
                                <Select
                                    value={blockObj.timeUnit || "minutes"}
                                    size={"small"}
                                    fullWidth
                                    className="nodrag"
                                    onChange={(e) => {
                                        const updatedValue = { ...blockObj, timeUnit: e.target.value };
                                        setBlockObj(updatedValue);
                                        debouncedUpdate(updatedValue); // Pass the updated value directly
                                    }}
                                >
                                    <MenuItem value={"minutes"}>Minutes</MenuItem>
                                    <MenuItem value={"hours"}>Hours</MenuItem>
                                    <MenuItem value={"days"}>Days</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item display="flex" xs={12} flexDirection={"column"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCatalogFunctionClick(999)}
                                >
                                    Add/Edit Function
                                </Button>
                            </Grid>

                            {blockObj.pollObject?.pollName?.trim() ? (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        width: "300px",
                                        height: "70px",
                                        display: "flex",
                                        flexDirection: "column",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {blockObj.pollObject?.pollName}
                                </Grid>
                            ) : (
                                ""
                            )}
                            {blockObj?.arrPollItems
                                ? blockObj.arrPollItems.map((item, index) => {
                                      if (item === "") return;
                                      return (
                                          <Grid
                                              key={`${item}-${index}`}
                                              item
                                              xs={12}
                                              style={{
                                                  width: "300px",
                                                  height: "65px",
                                                  display: "flex",
                                                  flexDirection: "column",
                                              }}
                                          ></Grid>
                                      );
                                  })
                                : ""}
                            {data.metaCatalog && existingFiles.length > 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        width: "300px",
                                        height: "35px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                ></Grid>
                            ) : (
                                ""
                            )}

                            <Box
                                sx={{
                                    pt: 3,
                                    display: "flex",
                                    flexDirection: "column", // Ensure vertical layout
                                    alignItems: "center", // Center items horizontally
                                    gap: 2,
                                    p: 2,
                                    width: "100%", // Ensure the box takes the full width
                                }}
                            >
                                {existingFiles?.map((file, i) => {
                                    return (
                                        <GridContainer key={file.downloadURL + i}>
                                            <Grid item display="flex" xs={6}>
                                                <ImageComponent image={file} width={["100px", "150px"]} />
                                            </Grid>
                                            <Grid item display="flex" xs={5}>
                                                <Name>{file.name}</Name>
                                            </Grid>

                                            <Grid item display="flex" xs={1}>
                                                <IconButton
                                                    onClick={() => handleDeleteFile(file)}
                                                    disabled={isLoading}
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </Grid>
                                            <GridDivider />
                                        </GridContainer>
                                    );
                                })}
                            </Box>
                            {data.metaCatalog === true && (
                                <Grid item display="flex" xs={12} flexDirection={"column"}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddCatalog}
                                        disabled={catalogs.length >= 9}
                                    >
                                        Add Catalog ( Max 9 )
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    <Handle
                        type="source"
                        position={Position.Right}
                        isConnectable={isConnectable}
                        id={"SEQUENTIAL"}
                        style={{
                            background: "#525252",
                            width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            opacity: 0.5,
                            position: "absolute",
                            top: "50%",
                            zIndex: 1000,
                        }}
                    />
                </div>

                {/* Catalog sections */}
                {catalogs.map((catalog, index) => (
                    <div key={index} className="text-updater-node" style={{ width: "300px" }} tabIndex={0}>
                        <Paper sx={{ padding: "8px" }}>
                            <Grid container display="flex" spacing={1}>
                                {/* Catalog Number Label */}
                                <Grid item xs={6}>
                                    <Typography variant="h6" gutterBottom>
                                        Catalog {index + 1}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <IconButton
                                        onClick={async () => {
                                            try {
                                                const response = await confirmation(
                                                    "Delete Catalog",
                                                    "Are you sure you want to delete this catalog and all its associated buttons?"
                                                );

                                                if (!response) return;

                                                setIsLoading(true);

                                                const currentCatalog = catalogs[index];
                                                const buttonIds = currentCatalog.buttonIds || [];

                                                // Delete all button nodes and their relations
                                                await Promise.all(
                                                    buttonIds.map(async (buttonId) => {
                                                        // Remove edges connected to this button
                                                        await removeBlockAndFunctionEdge(user, flow, {
                                                            id: buttonId,
                                                        });
                                                        // Remove all functions associated with this button
                                                        await removeAllFunctions(user, flow, {
                                                            id: buttonId,
                                                        });
                                                        // Remove the button block itself
                                                        await removeBlock(user, flow, { id: buttonId });
                                                    })
                                                );

                                                // Remove from local state
                                                const newCatalogs = catalogs.filter((_, i) => i !== index);
                                                setCatalogs(newCatalogs);

                                                // Clean up image preview
                                                setImagePreviews((prev) => {
                                                    const newPreviews = { ...prev };
                                                    delete newPreviews[index];
                                                    return newPreviews;
                                                });

                                                // Update Firebase
                                                const docRef = doc(
                                                    db,
                                                    "users",
                                                    user.id,
                                                    "whatsappFlows",
                                                    flow.id,
                                                    "blocks",
                                                    blockObj.id || blockObj.blockId
                                                );

                                                await updateDoc(docRef, { catalogs: newCatalogs });

                                                // Update local blockObj
                                                const updatedValue = {
                                                    ...blockObj,
                                                    catalogs: newCatalogs,
                                                };
                                                setBlockObj(updatedValue);

                                                notification(
                                                    "Catalog and all associated data deleted successfully."
                                                );
                                                triggerRerender();
                                            } catch (error) {
                                                console.error("Error deleting catalog:", error);
                                                notification(
                                                    "Error deleting catalog. Please try again.",
                                                    "error"
                                                );
                                            } finally {
                                                setIsLoading(false);
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Grid>

                                {/* Title Field */}
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="Title"
                                        className="nodrag nowheel"
                                        multiline
                                        rows={5}
                                        value={catalog.title}
                                        onChange={(e) => {
                                            const newCatalogs = [...catalogs];
                                            newCatalogs[index].title = e.target.value;
                                            setCatalogs(newCatalogs);
                                            const updatedValue = {
                                                ...blockObj,
                                                catalogs: newCatalogs,
                                            };
                                            debouncedUpdate(updatedValue);
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                cursor: "text !important",
                                            },
                                            userSelect: "text",
                                            pointerEvents: "auto",
                                        }}
                                    />
                                </Grid>

                                {/* Subtitle Field */}
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="Sub Title"
                                        className="nodrag nowheel"
                                        multiline
                                        rows={5}
                                        value={catalog.subTitle}
                                        onChange={(e) => {
                                            const newCatalogs = [...catalogs];
                                            newCatalogs[index].subTitle = e.target.value;
                                            setCatalogs(newCatalogs);
                                            const updatedValue = {
                                                ...blockObj,
                                                catalogs: newCatalogs,
                                            };
                                            debouncedUpdate(updatedValue);
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                cursor: "text !important",
                                            },
                                            userSelect: "text",
                                            pointerEvents: "auto",
                                        }}
                                    />
                                </Grid>

                                {/* File Upload Section */}
                                <Grid item display="flex" xs={12}>
                                    <Box display={"flex"} alignContent={"center"}>
                                        <UploadFilesButton
                                            handleFile={(files) => handleCatalogFiles(files, index)}
                                            label="Add File"
                                        />
                                    </Box>
                                </Grid>

                                {catalog?.buttons
                                    ? catalog.buttons.map((button, index) => {
                                          if (button === "") return;

                                          return (
                                              <Grid
                                                  key={`${index}`}
                                                  item
                                                  xs={12}
                                                  style={{
                                                      width: "300px",
                                                      height: "65px",
                                                      display: "flex",
                                                      flexDirection: "column",
                                                  }}
                                              ></Grid>
                                          );
                                      })
                                    : ""}

                                {/* Image Preview */}
                                {imagePreviews[index] && (
                                    <>
                                        <Box
                                            sx={{
                                                pt: 3,
                                                display: "flex",
                                                flexDirection: "column", // Ensure vertical layout
                                                alignItems: "center", // Center items horizontally
                                                gap: 2,
                                                p: 2,
                                                width: "100%", // Ensure the box takes the full width
                                            }}
                                        >
                                            <GridContainer key={imagePreviews[index].preview + index}>
                                                <Grid item display="flex" xs={6}>
                                                    <ImageComponent
                                                        image={imagePreviews[index].file}
                                                        width={["100px", "150px"]}
                                                    />
                                                </Grid>
                                                <Grid item display="flex" xs={5}>
                                                    <Name>{imagePreviews[index].file.name}</Name>
                                                </Grid>

                                                <Grid item display="flex" xs={1}>
                                                    <IconButton
                                                        onClick={() => handleDeleteCatalogFile(index)}
                                                        disabled={isLoading}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Grid>
                                                <GridDivider />
                                            </GridContainer>
                                        </Box>
                                    </>
                                )}

                                <Grid item display="flex" xs={12} flexDirection={"column"}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleCatalogFunctionClick(index)}
                                    >
                                        Add/Edit Function
                                    </Button>
                                </Grid>
                                {/* Delete Catalog Button */}
                            </Grid>
                        </Paper>
                    </div>
                ))}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="sm" // Make sure this is a valid value
                fullWidth
            >
                <DialogTitle>Add/Edit Function</DialogTitle>
                <DialogContent>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            {data.metaCatalog
                                ? "Add buttons (*Maximum 3 buttons)"
                                : "Add poll (*Maximum 12 poll selection)"}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {/* Only show question field for polls, not for buttons */}
                                {!data.metaCatalog && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="pollQuestion"
                                            type="text"
                                            name="pollQuestion"
                                            value={pollQuestion || " "}
                                            onChange={handleChangePollQuestion}
                                            placeholder="Poll Question"
                                            label="Poll Question"
                                            inputProps={{ maxLength: 60 }}
                                        />
                                    </Grid>
                                )}

                                {/* Add back the mapping of poll/button items */}

                                {(data.metaCatalog === true && currentCatalogIndex !== 999
                                    ? buttonItems
                                    : pollItems
                                ).map((item, i) => (
                                    <Grid item xs={12} key={i}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder={
                                                    data.metaCatalog
                                                        ? "Add button text"
                                                        : "Add poll selection"
                                                }
                                                label={
                                                    data.metaCatalog
                                                        ? "Add button text"
                                                        : "Add poll selection"
                                                }
                                                value={item}
                                                onChange={(e) =>
                                                    data.metaCatalog && currentCatalogIndex !== 999
                                                        ? handleChangeButtonItem(e, i)
                                                        : handleChangePollItem(e, i)
                                                }
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                    const pastedText = e.clipboardData.getData("text");
                                                    // Remove only emojis while keeping the rest of the text
                                                    const sanitizedText = pastedText.replace(
                                                        /[\u{1F300}-\u{1F9FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{1F191}-\u{1F251}]|[\u{1F004}]|[\u{1F0CF}]|[\u{1F170}-\u{1F171}]|[\u{1F17E}-\u{1F17F}]|[\u{1F18E}]|[\u{3030}]|[\u{2B50}]|[\u{2B55}]|[\u{2934}-\u{2935}]|[\u{2B05}-\u{2B07}]|[\u{2B1B}-\u{2B1C}]|[\u{3297}]|[\u{3299}]|[\u{303D}]|[\u{00A9}]|[\u{00AE}]|[\u{2122}]/gu,
                                                        ""
                                                    );

                                                    // Get the current value
                                                    const currentValue = item || "";

                                                    // Calculate where to insert the sanitized text
                                                    const selectionStart = e.target.selectionStart;
                                                    const selectionEnd = e.target.selectionEnd;

                                                    // Combine the text: text before cursor + sanitized pasted text + text after cursor
                                                    const newValue =
                                                        currentValue.slice(0, selectionStart) +
                                                        sanitizedText +
                                                        currentValue.slice(selectionEnd);

                                                    // Update the state while respecting the maxLength
                                                    if (data.metaCatalog && currentCatalogIndex !== 999) {
                                                        const newItems = [...buttonItems];
                                                        newItems[i] = newValue.slice(0, 60); // Respect maxLength
                                                        setButtonItems(newItems);
                                                    } else {
                                                        const newItems = [...pollItems];
                                                        newItems[i] = newValue.slice(0, 60); // Respect maxLength
                                                        setPollItems(newItems);
                                                    }
                                                }}
                                                inputProps={{
                                                    maxLength: 60,
                                                    pattern: "[^emoji]*",
                                                }}
                                            />
                                            <IconButton
                                                onClick={() =>
                                                    data.metaCatalog && currentCatalogIndex !== 999
                                                        ? handleDeleteButton(i)
                                                        : handleDeletePollItem(i)
                                                }
                                                // Disable delete button if it's the last remaining button
                                                disabled={
                                                    data.metaCatalog &&
                                                    currentCatalogIndex !== 999 &&
                                                    buttonItems.length <= 1
                                                }
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                ))}

                                <Grid item xs={12} container alignItems="center" direction="column">
                                    <Button
                                        onClick={handleAddPollItem}
                                        disabled={
                                            currentCatalogIndex !== null
                                                ? buttonItems.length >= 3
                                                : pollItems.length >= 12
                                        }
                                    >
                                        <AddCircleIcon fontSize="large" />
                                        {data.metaCatalog ? "Add Button" : "Add poll item"}
                                    </Button>
                                </Grid>

                                <Grid item xs={10}></Grid>

                                <Grid item xs={2} justifyContent="flex-end" display="flex">
                                    <Button onClick={handleSavePoll}>
                                        {data.metaCatalog ? "Save Buttons" : "Save Poll"}
                                    </Button>
                                    <Button onClick={handleDeleteQuestion}>
                                        {data.metaCatalog ? "Delete Buttons" : "Delete Poll"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Back</Button>
                </DialogActions>
            </Dialog>
            {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDeleting}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </>
    );
};

export default BlockNode;
