const isIQI = () => {
    const host = window.location.hostname;
    const isIQI =
        host === "iqi-pilot.web.app" ||
        host === "pilot.iqiglobal.com" ||
        host === "iqipilot.com" ||
        host === "iqpilot.ai";
    return isIQI;
};

export default isIQI;
